import { ArbExchangeModuleStatus } from "@/shared/api/endpoints/cextaker/types";
import { LiquidityGridStatus } from "@/shared/api/endpoints/grid/types";
import { MarketCheckStatus } from "@/shared/api/endpoints/marketcheck/market-check-parties-list/types";
import { MultiGridStatus } from "@/shared/api/endpoints/multigrinder/types";
import { ExchangeModuleStatus } from "@/shared/types/common-types";
import { StatusColorNames } from "@/shared/types/status-colors-types";

const gridColorMap: Record<LiquidityGridStatus, StatusColorNames> = {
	active: "green",
	stopped: "gray",
	initial: "gray",
	failed: "red",
	warning: "yellow",
};

const multiGridStatusMap: Record<MultiGridStatus, StatusColorNames> = {
	active: "green",
	stopped: "gray",
	initial: "gray",
	failed: "red",
	waiting: "yellow",
};

const exchangeModuleStatusMap: Record<ExchangeModuleStatus, StatusColorNames> = {
	active: "green",
	stopped: "gray",
	failed: "red",
};

const arbitrageModuleStatusMap: Record<ArbExchangeModuleStatus, StatusColorNames> = {
	active: "green",
	stopped: "gray",
	failed: "red",
	requested: "blue",
};

const marketCheckStatusMap: Record<MarketCheckStatus, StatusColorNames> = {
	active: "green",
	failed: "red",
	initial: "gray",
};

type StatusColorMap<T extends string> = Record<T, StatusColorNames>;

const statusMap: {
	[K in StatusMapKey]: StatusColorMap<StatusMapType[K]>;
} = {
	grid: gridColorMap,
	multigrid: multiGridStatusMap,
	exchangeModule: exchangeModuleStatusMap,
	arbitrageModule: arbitrageModuleStatusMap,
	marketCheck: marketCheckStatusMap,
};

type StatusMapType = {
	grid: LiquidityGridStatus;
	multigrid: MultiGridStatus;
	exchangeModule: ExchangeModuleStatus;
	arbitrageModule: ArbExchangeModuleStatus;
	marketCheck: MarketCheckStatus;
};
type StatusMapKey = keyof StatusMapType;

export const getStatusColorName = <T extends StatusMapKey>(
	type: T,
	status: StatusMapType[T]
): StatusColorNames => {
	const colorName = statusMap[type][status];

	if (!status || !colorName) return "gray";

	return colorName;
};
