export enum ColorTheme {
	DARK = "dark",
	LIGHT = "light",
}

export enum WSPingEnum {
	PING = "ping",
	EXCHANGE = "exchange",
}

export enum InputTypeEnum {
	TEXT = "text",
	PASSWORD = "password",
	NUMBER = "number",
}

export const SIDE_LIST = ["buy", "sell"] as const;
