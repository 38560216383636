import { PropertyKeyKeys, PropertyKeyValues } from "../types/common-types";

type NonFalsy<T> = T extends false | 0 | "" | null | undefined | 0n ? never : T;

export const filterBoolean = <T>(arr: T[]) => arr.filter(Boolean) as NonFalsy<T>[];
/**
 *
 * @param arr - array of simple data types
 * @param v - the element to be removed from the array
 */
export const deleteElem = (arr: string[] | number[], v: string | number) => {
	const foundIndex = arr.findIndex((el) => el === v);

	if (foundIndex !== -1) {
		arr.splice(foundIndex, 1);
	}
};

/**
 *
 * @param arr - array of objects to be mapped
 * @param key - the key by which the mapping will be performed
 * @returns mapped object by the specified key
 */
export const arrayToMapObj = <T, K extends PropertyKeyKeys<T>>(arr: T[], key: K) => {
	type TPropKeyValues = PropertyKeyValues<T>;

	return Object.fromEntries(arr.map((el) => [el[key], el])) as Record<TPropKeyValues[K], T>;
};

export const isEmptyArray = (value: any) => Array.isArray(value) && !value.length;
