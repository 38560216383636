import { matchPath } from "react-router-dom";

import { MainRoutes } from "@/shared/routing";
import { Nullish } from "@/shared/types/common-types";

const RESTRICTED_REDIRECTS = [MainRoutes.Root, MainRoutes.Login];

const urlRoutesMatch = (url: string, routes: string[]) => {
	const isMatch = routes.some((route) => matchPath(url, route));
	return isMatch;
};

/**
 * Validates a redirect URL by checking if it matches any restricted routes.
 * @param redirectUrl - The redirect URL to validate.
 * @returns The valid redirect URL if it doesn't match any restricted routes, otherwise null.
 */
export const validateRedirectUrl = (redirectUrl: Nullish<string>) => {
	if (!redirectUrl) {
		return null;
	}

	const isValidRedirect = !urlRoutesMatch(redirectUrl, RESTRICTED_REDIRECTS);

	return isValidRedirect ? redirectUrl : null;
};
