import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { httpClientIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import "reflect-metadata";
import "tailwindcss/tailwind.css";

import "@/app/styles/global.scss";
import { DeviceProvider } from "@/shared/hooks/use-device-check";
import "@/shared/lib/chartjs";
import "@/shared/lib/dayjs";
import { AppContext, appState } from "@/shared/stores/app-state";
import "@/shared/utils/yup/initializer";

import { App } from "./app";
import "./bundle";
import { routesItems } from "./routing";

Sentry.init({
	// dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		httpClientIntegration(),
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: true,
		}),
	],
	sendDefaultPii: true,
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost"],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
	{
		path: "/*",
		element: <App />,
		children: routesItems,
	},
]);

createRoot(document.getElementById("root") as HTMLElement).render(
	<AppContext.Provider value={appState}>
		<DeviceProvider>
			<RouterProvider router={router} future={{ v7_startTransition: true }} />
		</DeviceProvider>
	</AppContext.Provider>
);
