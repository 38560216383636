import { extend } from "dayjs";
import utc from "dayjs/plugin/utc";
import { makeAutoObservable, toJS } from "mobx";

import { WSPingEnum } from "@/shared/constants/common";
import { capitalizeOnlyFirstLetter } from "@/shared/utils/string";

extend(utc);

export class WSStore {
	private _connectionType = WSPingEnum.PING;

	private _type = WSPingEnum.PING;

	private _intervalHandler = 5000;

	private _firstRequest = 0;

	private _delay = 50;

	constructor() {
		makeAutoObservable(this);
	}

	get getFirstRequest(): number {
		return this._firstRequest;
	}

	get getIntervalHandler(): number {
		return this._intervalHandler;
	}

	get getConnectionType(): WSPingEnum {
		return this._type;
	}

	get getType(): WSPingEnum {
		return this._type;
	}

	get getDelay(): number {
		return this._delay;
	}

	get getStatusInfo() {
		let status = "";
		let color = "";

		switch (true) {
			// TODO: Use token colors when design be ready
			case this._delay === 0:
				status = this._type === WSPingEnum.PING ? "No connection" : "No data";
				color = "#CDCED1";
				break;

			case this._delay > 150:
				status = "bad";
				color = "#D84E4E";
				break;

			case this._delay > 80:
				status = "normal";
				color = "#F2BD01";
				break;

			case this._delay > 50:
				status = "good";
				color = "#007DFF";
				break;

			case this._delay <= 50:
				status = "great";
				color = "#69B40A";
				break;
		}

		return toJS({
			title: capitalizeOnlyFirstLetter(status),
			color,
		});
	}

	setDelay = (delay: number) => {
		this._delay = delay;
	};

	setType = (type: WSPingEnum) => {
		this._type = type;
	};

	setConnectionType = (connectionType: WSPingEnum) => {
		this._connectionType = connectionType;
	};

	setFirstRequest = (firstRequest: number) => {
		this._firstRequest = firstRequest;
	};

	getDelayTitle = (): string => {
		const title = this._connectionType === WSPingEnum.PING ? "Server" : "Exchange";

		return !this._delay ? "No connection" : `${title} delay ${this._delay} ms`;
	};
}
