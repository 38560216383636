import { LocalStorageKeys } from "./local-storage-keys";

const HTTPS_PROTOCOL = "https://";
const WS_PROTOCOL = "wss://";
const API_VERSION = "/api/v1";
const WS_PREFIX = "/ws/";

const baseUrl = import.meta.env.VITE_APP_API_URL;

export const availableOriginsUrl = import.meta.env.VITE_APP_AVAILABLE_ORIGINS_URL;

export const enableApiOriginSelector =
	import.meta.env.VITE_APP_ENABLE_API_ORIGIN_SELECTOR === "true";

let backendUrl = baseUrl;

if (enableApiOriginSelector) {
	const storedUrl = localStorage.getItem(LocalStorageKeys.ApiBaseUrl);

	if (storedUrl) {
		backendUrl = storedUrl;
	}
}

export const apiUrl = HTTPS_PROTOCOL + backendUrl + API_VERSION;

export const wsUrl = WS_PROTOCOL + backendUrl + WS_PREFIX;

export const enableStories = JSON.parse(import.meta.env.VITE_APP_ENABLE_STORIES) as boolean;

export const mfeUrl = import.meta.env.VITE_APP_MFE_URL;
