import { responseHandler } from "@/shared/api/fetch/response-handler";
import { ApiModules } from "@/shared/api/types";
import { getApiUrl } from "@/shared/api/utils/get-api-url";
import { Note } from "@/shared/types/common-types";
import { buildQueryUrl } from "@/shared/utils/url";

import {
	CEXBotDTO,
	CEXCurrentBindingsResponse,
	SetCEXCurrentBindingsRequestProps,
	SetCEXCurrentBindingsResponse,
} from "./types";

export * from "./accounts";

export const getCEXBot = async (bot_uuid: string) =>
	responseHandler.handler<CEXBotDTO>(
		{
			url: getApiUrl(ApiModules.Bot, `${bot_uuid}/get-bot`),
			method: "GET",
		},
		"getCEXBot"
	);

export const getCEXInfo = async (bot_uuid: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Bot, `${bot_uuid}/get-info`),
		method: "GET",
	});

export const getCEXCurrentBindings = async (botUUID: string) =>
	responseHandler.handler<CEXCurrentBindingsResponse>({
		url: getApiUrl(ApiModules.Bot, `${botUUID}/current-bindings`),
		method: "GET",
	});

export const setCEXCurrentBindings = async ({ data, botUUID }: SetCEXCurrentBindingsRequestProps) =>
	responseHandler.handler<SetCEXCurrentBindingsResponse>({
		url: getApiUrl(ApiModules.Bot, `${botUUID}/current-bindings`),
		method: "PUT",
		data,
	});

export const getBotSettingsCex = async (botName: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Bot, `${botName}/settings`),
		method: "GET",
	});

export const setBotSettings = async (bot_uuid: string, data: any) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Bot, `${bot_uuid}/settings`),
		method: "PUT",
		data,
	});

export const getConstraints = async (bot_uuid: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Bot, `/${bot_uuid}/rules`),
		method: "GET",
	});

export const getSpreadPeriods = async (botName: string, period: number) => {
	const stopPoint = Math.round(Date.now() / 1000);

	return responseHandler.handler({
		url: getApiUrl(
			ApiModules.Bot,
			`${botName}/spread-decompressions?start=${stopPoint - period}&stop=${stopPoint}`
		),
		method: "GET",
	});
};

export const getVerifyPeriods = async (botName: string, period: number) => {
	const stopPoint = Math.round(Date.now() / 1000);

	return responseHandler.handler({
		url: getApiUrl(
			ApiModules.Bot,
			`${botName}/verify-orders?start=${stopPoint - period}&stop=${stopPoint}`
		),
		method: "GET",
	});
};

export const startBotCex = async (botName: string): Promise<any> =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Bot, `${botName}/start`),
		method: "POST",
	});

export const stopBotCex = async (botName: string): Promise<any> =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Bot, `${botName}/stop`),
		method: "POST",
	});

export const getHistory = async (botName: string, start: number, stop: number) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Bot, `${botName}/history?start=${start}&stop=${stop}`),
		method: "GET",
	});

export const getCEXNotes = async (market: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Bot, `${market}/note`),
		method: "GET",
	});

export const setCEXNotes = async (market: string, data: Note) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Bot, `${market}/note`),
		method: "PUT",
		data,
	});

export const moveBotNewParty = async (bot_uuid: string, party: string) =>
	responseHandler.handler({
		url: getApiUrl(ApiModules.Bot, `/${bot_uuid}/party`),
		method: "PUT",
		data: {
			party,
		},
	});

export const getCEXBots = async (party?: string) => {
	const url = buildQueryUrl("get-bots", { party });

	return responseHandler.handler({
		url: getApiUrl(ApiModules.Empty, url),
		method: "GET",
	});
};

export const getRecentParties = async () =>
	responseHandler.handler(
		{
			url: getApiUrl(ApiModules.Empty, "get-bots-latest"),
			method: "GET",
		},
		"getRecentParties"
	);
