import { Nullable } from "@/shared/types/common-types";

import { ApiModules, RequestConfig } from "../types";

import { getApiUrl } from "./get-api-url";
import { setAbilities, setAccessToken, setRefreshToken } from "./get-token";

export const updateRefreshTokenStorage = (data: any) => {
	const { accessToken, refreshToken, abilities } = data;

	if (accessToken) setAccessToken(accessToken);

	if (refreshToken) setRefreshToken(refreshToken);

	if (abilities) setAbilities(abilities);
};

export const refreshTokenRequestConfig = (token: Nullable<string>): RequestConfig => ({
	url: getApiUrl(ApiModules.Empty, "/refresh-token"),
	method: "POST",
	data: { refreshToken: token },
	auth: false,
});
