import { useEffect, useMemo, useState } from "react";
import { Select } from "antd";

import { availableOriginsUrl } from "@/shared/constants/environments";
import { LocalStorageKeys } from "@/shared/constants/local-storage-keys";
import { SelectorValue } from "@/shared/utils/forms";

interface Origin {
	hostname: string;
	namespace: string;
	app: string;
}

type OriginOption = SelectorValue<string, string>;

export const OriginSelect = () => {
	const [options, setOptions] = useState<OriginOption[]>([]);
	const selectedOrigin = localStorage.getItem(LocalStorageKeys.ApiBaseUrl);

	const handleOriginChange = (_: unknown, { value }: OriginOption) => {
		localStorage.setItem(LocalStorageKeys.ApiBaseUrl, value);
		window.location.reload();
	};

	const selectedOption = useMemo(
		() => options.find((option) => option.value === selectedOrigin),
		[options, selectedOrigin]
	);

	useEffect(() => {
		fetch(availableOriginsUrl)
			.then((response: Response) => response.text())
			.then((responseText) => {
				const data = JSON.parse(responseText) as Origin[];

				if (Array.isArray(data))
					setOptions(data.map((item) => ({ label: item.namespace, value: item.hostname })));
			});
	}, []);

	return (
		<Select
			onSelect={handleOriginChange}
			className="min-w-[200px]"
			value={selectedOption}
			options={options}
			showSearch
		/>
	);
};
